<template>
  <div>
    <TagbyFilterV2
      :initialParams="state.filterParams"
      :categoryOptions="categoryOptions"
      :leftWidth="3"
      @search="search"
    >
      <template #buttons>
        <BButton
          variant="outline-secondary"
          class="mr-1"
          :to="{ name: 'message-app-push-create' }"
        >
          <feather-icon
            icon="PlusIcon"
            class="mr-50"
          />
          <span class="align-middle">Add New</span>
        </BButton>
      </template>
    </TagbyFilterV2>
    <TagbyList
      v-model="state.currentPage"
      :items="getters.items"
      :fields="tableColumns"
      :busy="state.isBusy"
      :current-page="state.currentPage"
      :total-rows="state.totalRows"
      :per-page="state.perPage"
      @page-change="changePage"
      @sort-changed="changeSort"
    >
      <template #cell(idx)="data">
        <BLink :to="{ name: 'message-app-push-detail', params: { idx: data.value} }">
          {{ data.value }}
        </BLink>
      </template>

      <template #cell(admin)="data">
        <div>
          {{ data.item.admName }}
        </div>
        <div>
          <small class="text-muted">{{ data.item.admEmail }}</small>
        </div>
      </template>

      <template #cell(target_count)="data">
        <div style="min-width: 50px">
          {{ data.value }}
        </div>
      </template>

      <template #cell(success_count)="data">
        <div style="min-width: 50px">
          {{ data.value }}
        </div>
      </template>

      <template #cell(fail_count)="data">
        <div style="min-width: 50px">
          {{ data.value }}
        </div>
      </template>

      <template #cell(send_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>

      <template #cell(created_at)="data">
        <TagbyDatetimeColumn :value="data.value" />
      </template>
    </TagbyList>
  </div>
</template>

<script>
import { defineComponent } from "@vue/composition-api"
import {
  BLink,
  BButton,
} from "bootstrap-vue"
import useInitialize from "./useInitialize"
import useItems from "./useItems"
import TagbyFilterV2 from "@/components/TagbyFilterV2.vue"
import TagbyList from "@/components/TagbyList.vue"
import TagbyDatetimeColumn from "@/components/TagbyDatetimeColumn.vue"
import state from "./state"
import getters from "./getters"

export default defineComponent({
  components: {
    BLink,
    BButton,
    TagbyFilterV2,
    TagbyList,
    TagbyDatetimeColumn,
  },
  setup() {
    const {
      initialize,
    } = useInitialize()
    initialize()

    const {
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      fetchItems,
      changePage,
      changeSort,
    } = useItems()
    fetchItems()

    return {
      state,
      getters,
      categoryOptions,
      hasRequestTime,
      tableColumns,
      search,
      fetchItems,
      changePage,
      changeSort,
    }
  },
})
</script>
