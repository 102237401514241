import {
  reactive,
  computed,
} from '@vue/composition-api'

import state from './state'

import {
  indexing,
  grouping,
} from '@/utils/value'

const items = computed(() => {
  const admByIdx = indexing(state.admList, 'idx')
  const groupedResultCountByMessageGroupIdx = grouping(state.resultCountList, 'message_group_idx')
  return state.messageGroupList.map(m => {
    const adm = admByIdx[m.admin_idx]
    const resultCounts = groupedResultCountByMessageGroupIdx[m.idx]
    const successCountObj = resultCounts?.find(r => r.result === 'SUCCESS')
    const failCountObj = resultCounts?.find(r => r.result === 'FAIL')
    return {
      ...m,
      admIdx: adm?.idx,
      admName: adm?.name,
      admEmail: adm?.email,
      success: state.isLoading ? 'loading' : successCountObj?.result_count ?? 0,
      fail: state.isLoading ? 'loading' : failCountObj?.result_count ?? 0,
    }
  })
})

export default reactive({
  items,
})
