import {
  computed,
} from "@vue/composition-api"
import useToast from "@/utils/toast"
import {
  syncronizeParamsWithUrl,
  replaceRouterQuery,
} from "@/utils/syncUrl"
import {
  values,
} from "@/utils/value"

import state from "./state"
import api from "./api"

export default () => {
  const { makeToast } = useToast()

  const searchParams = computed(() => ({
    page: state.currentPage,
    size: state.perPage,
    total: state.totalRows,
    ...state.filterParams,
  }))

  const categoryOptions = [
    {
      label: "CREATED_AT",
      paramName: "created_at",
      format: "date",
    },
  ]

  const fetchMessageGroupList = () => api.fetchMessageGroupList({
    ...searchParams.value,
    sort_by: state.sortBy,
  }).then(response => {
    const resData = response.data
    state.messageGroupList = resData.data
    state.totalRows = resData.page_info.total_count
    state.filterParams = {
      ...state.filterParams,
      total: resData.page_info.total_count,
    }
  })

  const fetchAdmList = () => api.fetchAdmList({
    adm_idx_list: values(state.messageGroupList, "admin_idx"),
  }).then(response => {
    state.admList = response.data.data
  })

  const fetchItems = () => {
    state.isBusy = true
    fetchMessageGroupList()
      .then(() => fetchAdmList())
      .then(() => {
        replaceRouterQuery(searchParams.value)
      })
      .catch(() => {
        makeToast("danger", "목록을 쿼리하는데 실패 했습니다")
      })
      .finally(() => {
        state.isBusy = false
      })
  }

  const search = params => {
    state.currentPage = 1
    state.filterParams = {
      ...params,
    }
    replaceRouterQuery(searchParams.value)
    fetchMessageGroupList()
  }

  const tableColumns = [
    { key: "idx", label: "idx", sortable: true },
    { key: "admin", label: "admin" },
    { key: "message", label: "메세지" },
    { key: "target_count", label: "타겟" },
    { key: "success_count", label: "성공" },
    { key: "fail_count", label: "실패" },
    { key: "state", label: "상태" },
    { key: "send_at", label: "전송일시" },
    { key: "created_at", label: "생성일시" },
  ]

  const changePage = params => {
    state.currentPage = params.page
    fetchItems()
  }

  const changeSort = ctx => {
    state.sortBy = ctx.sortDesc ? ctx.sortBy : `-${ctx.sortBy}`
    fetchItems()
  }

  return {
    categoryOptions,
    tableColumns,
    fetchItems,
    search,
    changePage,
    changeSort,
  }
}
